.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Top-contanier {
    background-color: rgba(220, 220, 255, .85);
}

/*        background-color: rgba(0, 0, 33, .85); */
.Main-contanier {

}
    /* background-color: rgba(240, 240, 255, .85); */
.main-header {

}

/* background-color: rgba(240, 240, 255, .85); */
.nav-barx {

    text-color: rgba(0, 240, 0, .85);
}


.site-header {
    background-color: rgba(255, 255, 255, .85);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
}

far {
    background-color: rgba(200, 205, 205, .85);
}

.container {
    max-width: 960px;
}

@media (min-width: 768px)
.container {
    max-width: 720px;
}

/*     box-shadow: 0px 0px 2px 1px rgba(0, 0, 255, .2); */
.card {
/*    border-color: blue; */

}


header .btn-noborder {
    border:0px solid transparent;
    background-color: transparent;
    margin-right: 10px !important;
}
/*
    background-color: #eee;
    box-shadow: 4px 4px #aaa8;
*/

.far,.fas {
    color: rgba(16, 64, 255, .7);
}

.fa-check-circle {
    color: rgba(0, 255, 0, .7);    
}



.mainHeader,
.main-header,
.card-header,
.modal-header
{
    background-color: rgba(230, 230, 245, .3);
}

.card-footer
{
    background-color: rgba(220, 220, 245, .1);
    font-size: calc(10px + 0.5vmin);
}

.card {
    margin-top: 10px !important;
}

.alert
.alert-heading,
.p
{
    font-size: calc(10px + 0.5vmin);
}




button:active,
button:focus {
    outline: none;
}


.btn2,
.btn2:active,
.btn2:hover
{
    border:0px solid transparent;
    margin-right: 10px !important;
    background-color: transparent;
}


.Card .ListItem {
    margin: 0px !important;
}


/*
.btn2:active {
    outline: none;
}
.btn2:active:focus {
    outline: none;
}
.btn2:focus {
    outline: none;
}
*/
