.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Top-contanier {
    background-color: rgba(220, 220, 255, .85);
}

.Main-contanier {
/*        background-color: rgba(0, 0, 33, .85); */
}

.site-header {
    background-color: rgba(0, 0, 33, .85);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
}


.pricing-header {
    max-width: 700px;
}


.container {
    max-width: 960px;
}

@media (min-width: 768px)
.container {
    max-width: 720px;
}

